import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import { decryptToken } from "../../../../../AppUtility";

import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Accordion, Collapse } from "react-bootstrap";
import "./NewGrievance.css";

const EditClosedGrievance = (props) => {
  const [loading, setLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState({});
  const [selectedSupervisor, setSelectedSupervisor] = useState({});

  const [stationList, setStationList] = useState([]);
  const [selectedStation, setSelectedStation] = useState({});

  const [sectionList, setSectionList] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});

  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});

  const [nextStage, setNextStage] = useState("");
  const [currentStageArr, setCurrentStageArr] = useState([
    "Employee",
    "HOS",
    "HOD",
    "HR",
    "MD",
  ]);
  const [currentStage, setCurrentStage] = useState("");

  const [firstRaisedDate, setFirstRaisedDate] = useState(new Date());
  const [workEnvironment, setWorkEnvironment] = useState(false);
  const [employeeRtn, setEmployeeRtn] = useState(false);

  const [push, setPush] = useState(false);

  const [postBtnState, setPostBtnState] = useState(false);

  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [disableField, setDisabledField] = useState(true);

  const [grievanceNo, setGrievanceNo] = useState("");
  const [employeeNo, setEmployeeNo] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [supervisorNo, setSupervisorNo] = useState("");
  const [supervisorName, setSupervisorName] = useState("");

  const [grievanceDate, setGrievanceDate] = useState(new Date());
  const [grievanceType, setGrievanceType] = useState("");
  const [subject, setSubject] = useState("");
  const [grievanceDescription, setGrievanceDescription] = useState("");
  const [stepTaken, setStepTaken] = useState("");
  const [outCome, setOutCome] = useState("");
  const [commEnt, setCommEnt] = useState("");
  const [recommendAtion, setRecommendAtion] = useState("");
  const [hodrecommendation, setHODRecommendation] = useState("");
  const [hrrecommendation, setHRRecommendation] = useState("");

  const [disableEsc, setDisableEsc] = useState(false);
  const [extensionF, setExtensionF] = useState(false);
  const [stage, setStage] = useState("");
  const [stageMessage, setStageMessage] = useState("");
  const [cycle, setCycle] = useState("");

  const toggleCollapse = (from) => {
    switch (from) {
      case "reversal":
        setExtensionF(!extensionF);
        break;

      default:
        setExtensionF(false);
        break;
    }
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          secureLocalStorage.getItem("userDetails")
        )}`,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_API_S_LINK}/grievance/supervisorgrievance/${props.location.state[0].datum[0].gid}`,
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          setGrievanceNo(response.data.pending[0].gid);
          setEmployeeNo(response.data.pending[0].employeeNo);
          setEmployeeName(response.data.pending[0].employeeName);
          setSupervisorNo(response.data.pending[0].supervisorNo);
          setSupervisorName(response.data.pending[0].supervisorName);
          setGrievanceType(response.data.pending[0].type);
          setSubject(response.data.pending[0].subject);
          setGrievanceDescription(response.data.pending[0].description);
          setStepTaken(response.data.pending[0].steps);
          setOutCome(response.data.pending[0].outcome);
          setCommEnt(response.data.pending[0].comments);
          setCycle(response.data.pending[0].cycle);
          setHODRecommendation(response.data.pending[0].hodComment)
          setHRRecommendation(response.data.pending[0].hrComment)
          setEmployeeList(response.data.employeeList);
          // setDisabledField(false)
          if(parseInt(response.data.pending[0].cycle) === 3){
            setDisableEsc(true)
          }
          setLoading(false);
        }
        if (response.status === 404) {
          swal("Oh!", response.data.message, "error");
          // => console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log({ err: err });
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
      });
  }, []);

  const uploadGrievanceToSupervisor = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          secureLocalStorage.getItem("userDetails")
        )}`,
      },
    };

    let data = {
      GID: grievanceNo,
      Type: grievanceType,
      Subject: subject.substring(0, 250),
      Description: grievanceDescription.substring(0, 250),
      Steps: stepTaken,
      Outcome: outCome,
      Comments: commEnt,
      // EmpID:selectedEmp.value,
      // Station:selectedStation.value,
      // Section:selectedSection.value,
      // Dept:selectedDepartment.value,
      // NextStageStaff:selectedRecipient.value,
      // CurrentStage:currentStage,
      // NextStage:nextStage,
      // GrievanceDate:grievanceDate,
      // DateofIssue:firstRaisedDate,
      // WorkEnv:workEnvironment,
      // EmployeeRln:employeeRtn,
      // Recommendation:recommendAtion
    };
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Forward",
      icon: "warning",
      dangerMode: true,
    })
      .then((willCreate) => {
        setPostBtnState(true);
        if (willCreate) {
          return axios.post(
            `${process.env.REACT_APP_API_S_LINK}/grievance/grievanceupdate`,
            data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          setPush(true);
          setGrievanceNo(response.data.gid);
          swal("Success!", "Grievance Sent to Supervisor", "success");
        }
        if (response.status === 404) {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
        setPostBtnState(false);
      });
  };
  const forwardNext = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          secureLocalStorage.getItem("userDetails")
        )}`,
      },
    };

    // let data ={
    //   EmpID:selectedEmp.value,

    // }
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Move",
      icon: "warning",
      dangerMode: true,
    })
      .then((willCreate) => {
        setPostBtnState(true);
        if (willCreate) {
          return axios.get(
            `${process.env.REACT_APP_API_S_LINK}/grievance/forwardgrievance/${grievanceNo}`,
            // data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          // => console.log(response.data);
          setPush(true);
          setGrievanceNo(response.data.return_value);
          swal("Success!", "Grievance Card Moved", "success");
        }
        if (response.status === 404) {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
        setPostBtnState(false);
      });
  };

  let HROption = "";
  let HODRemark = "";
  let HRRemark = "";
  if (parseInt(cycle) > 1) {
    HROption = (
      <>
        <option value="2">HR</option>
      </>
    );
  }
  if (parseInt(cycle) >= 2) {
    HODRemark = (
      <>
        <div className="col-xl-12 col-sm-12">
          <div className="form-group">
            <label htmlFor="">HOD Recommendation</label>
            <textarea
              className="form-control"
              cols="30"
              rows="3"
              name="recommendAtion"
              placeholder="max 240 characters"
              value={hodrecommendation}
              // onChange={(e) => setRecommendAtion(e.target.value)}
              disabled={true}
            ></textarea>
          </div>
        </div>
      </>
    );
  }
  if (parseInt(cycle) === 3) {
   
    HRRemark = (
      <>
        <div className="col-xl-12 col-sm-12">
          <div className="form-group">
            <label htmlFor="">HR Recommendation</label>
            <textarea
              className="form-control"
              cols="30"
              rows="3"
              name="recommendAtion"
              placeholder="max 240 characters"
              value={hrrecommendation}
              // onChange={(e) => setRecommendAtion(e.target.value)}
              disabled={true}
            ></textarea>
          </div>
        </div>
      </>
    );
  }
  const EscalateAction = (e) => {
    e.preventDefault();
    if (stage === "") {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          secureLocalStorage.getItem("userDetails")
        )}`,
      },
    };

    let data = {
      GID: grievanceNo,
      EscalationReason: stageMessage,
      EscalationRank: parseInt(stage),
      SelectedHandler:selectedRecipient.value
    };

    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Escalate the Record?",
      icon: "warning",
      dangerMode: true,
    })
      .then((willCreate) => {
        if (willCreate) {
          return axios.post(
            `${process.env.REACT_APP_API_S_LINK}/grievance/grievanceescalation`,
            data,
            config
          );
        }
      })

      .then(function (response) {
        if (response.status === 200) {
          swal("Success!", "Grievance Record Escalated.", "success");
        }
        if (response.status === 404) {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          swal("Oh!", err.response.data.message, "error");
        } else {
          swal("Oh!", err.message, "error");
        }
        console.log({ err: err });
      });
  };

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="headerDiv2 text-center"></div>
          <div className="jobRow">
            <div id="preloader-home">
              <div
                className="sk-three-bounce"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="title mb-4">
                <span className="fs-18 text-black font-w600">
                  New Grievance Creation Card
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="card rounded-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-3 col-sm-6">
                      <div className="form-control1">
                        <label htmlFor="">Grievance</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Grievance No."
                          value={grievanceNo}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                      <div className="form-control1">
                        <label htmlFor="">Employee No.</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Employee No."
                          value={employeeNo}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                      <div className="form-control1">
                        <label htmlFor="">Employee Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Employee Name."
                          value={employeeName}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                      <div className="form-control1">
                        <label htmlFor="">Supervisor No.</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Supervisor No."
                          value={supervisorNo}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-xl-3 col-sm-6">
                      <div className="form-control1">
                        <label htmlFor="">Supervisor Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Supervisor Name."
                          value={supervisorName}
                          disabled
                        />
                      </div>
                    </div>

                    {/* <div className="col-xl-3 col-sm-3">
                      <div className="form-group">
                        <label htmlFor="">Next Stage Rank</label>
                        <select
                          name="qualifiedForPromo"
                          id=""
                          className="form-control"
                          onChange={(e) => setNextStage(e.target.value)}
                        >
                          <option>Choose</option>
                          <option value="Supervisor">Supervisor</option>
                          <option value="HOD">HOD</option>
                          <option value="HR">HR</option>
                          <option value="HEAD-HR">HEAD HR</option>
                          <option value="MD">MD</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="title mb-4">
                <span className="fs-18 text-black font-w600">
                  Grievance Details
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="card rounded-0">
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-xl-3 col-sm-6">
                  <div className="form-control1">
                    <label htmlFor="">Grievance Date</label>
                    <DatePicker
                      selected={grievanceDate}
                      onChange={(date) => setGrievanceDate(date)}
                    />
                  </div>
                </div> */}

                    <div className="col-xl-3 col-sm-3">
                      <div className="form-group">
                        <label htmlFor="">Grievance Type</label>
                        <select
                          name="grievanceType"
                          id=""
                          className="form-control"
                          value={grievanceType}
                          onChange={(e) => setGrievanceType(e.target.value)}
                          disabled={disableField}
                        >
                          <option>Choose</option>
                          <option value="Individual">Individual</option>
                          <option value="Collective">Collective</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-xl-9 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder=""
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          disabled={disableField}
                        />
                      </div>
                    </div>

                    <div className="col-xl-12 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Grievance Description</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="3"
                          name="grievanceDescription"
                          placeholder="max 240 characters"
                          value={grievanceDescription}
                          onChange={(e) =>
                            setGrievanceDescription(e.target.value)
                          }
                          disabled={disableField}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">Step Taken</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="3"
                          name="stepTaken"
                          placeholder="max 240 characters"
                          value={stepTaken}
                          onChange={(e) => setStepTaken(e.target.value)}
                          disabled={disableField}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">Outcome</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="3"
                          name="outCome"
                          placeholder="max 240 characters"
                          value={outCome}
                          onChange={(e) => setOutCome(e.target.value)}
                          disabled={disableField}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-xl-12 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">Comment</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="3"
                          name="commEnt"
                          placeholder="max 240 characters"
                          value={commEnt}
                          onChange={(e) => setCommEnt(e.target.value)}
                          disabled={disableField}
                        ></textarea>
                      </div>
                    </div>
                    {HODRemark}
                    {HRRemark}
                    {/* <div className="col-xl-12 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="">Recommendation</label>
                    <textarea
                      className="form-control"
                      cols="30"
                      rows="3"
                      name="recommendAtion"
                      placeholder="max 240 characters"
                      value={recommendAtion}
                      onChange={(e) => setRecommendAtion(e.target.value)}
                      disabled={disableField}
                    ></textarea>
                  </div>
                </div> */}
                  </div>
                </div>

                <div className="card-footer">
                  <div className="text-right">
                    <button
                      className="btn btn-danger mx-2"
                      onClick={() => toggleCollapse("reversal")}
                      aria-controls="example-collapse-text"
                      aria-expanded={extensionF}
                      disabled={postBtnState}
                    >
                      <i className="fa fa-repeat px-1"></i>
                      Escalate the Grievance
                    </button>
                  </div>

                  <div className="row">
                    <Collapse in={extensionF}>
                      <div id="example-collapse-text">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                Select Escalation Personnel
                              </label>
                              <select
                                name="stage"
                                id=""
                                onChange={(e) => setStage(e.target.value)}
                                className="form-control"
                              >
                                <option value="">Choose Level</option>
                                <option value="1">HOD</option>
                                {HROption}
                              </select>
                            </div>
                          </div>
                        
                          <div className="col-md-8">
                            <label foo="">Escalation Reason</label>
                            <textarea
                              className="w-100 form-control"
                              name="stageMessage"
                              rows="2"
                              placeholder="Summary 240 characters"
                              value={stageMessage}
                              onChange={(e) => setStageMessage(e.target.value)}
                              disabled={false}
                            ></textarea>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">
                                (Optional) Select your case handler
                              </label>

                              <Select
                                defaultValue={selectedRecipient}
                                onChange={setSelectedRecipient}
                                options={employeeList}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn-danger rounded-0 w-100 mt-2"
                              onClick={EscalateAction}
                              disabled={disableEsc}
                            >
                              Escalate <i className="fa fa-repeat"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default withRouter(EditClosedGrievance);
